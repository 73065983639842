#navbar {
    flex: 0 0 auto;
    height: 112px;
    position: relative;
    background: var(--navbar);
    padding: 0;

    .page-section-inner {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    .navbar-logo {
        border-radius: 3px;
        height: 92px;
        width: auto;
        display: block;
    }

    .nav-item {
        color: var(--text);
        font-weight: 500;
        padding: 12px;
        margin: 0 5px;
        border-radius: 3px;
        font-size: 15px;
        white-space: nowrap;
        text-decoration: none;
        position: relative;
        user-select: none;
        cursor: pointer;
        transition: 150ms ease;
    
        &:after {
            content: '';
            position: absolute;
            width: 100%;
            transform: scaleX(0);
            height: 2px;
            bottom: 0;
            left: 0;
            background-color: var(--primary);
            transform-origin: bottom right;
            transition: transform 0.25s ease-out;
        }

        &:hover:after {
            transform: scaleX(1);
            transform-origin: bottom left;
        }
    }
}